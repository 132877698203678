import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

const App = () => {
  const [locale, setLocale] = useState();

  const theme = createTheme(
    {
      typography: {
        fontFamily: "Nunito",
        fontSize: 14,
        fontWeight: 600,
      },
      overrides: {
        MuiTableRow: {
          root: {
            maxHeight: 98,
          },
        },
        MuiCssBaseline: {
          "@global": {
            html: {
              WebkitFontSmoothing: "auto",
            },
            body: {
              fontFamily: "Nunito",
              fontWeight: 600,
              fontSize: 14,
            },
          },
        },
      },
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#ededed", // cor do fundo da barra de rolagem
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#224abe", // cor do "polegar" da barra de rolagem
          //borderRadius: "10px", // arredondamento do "polegar"
        },
        /*"&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#660000", // cor ao passar o mouse
        },*/
      },
      palette: {
        primary: { main: "#224abe" },
      }
    },
    locale
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
};

export default App;
